import React from "react";

const usersBtnData = [
  {
    id: 1,
    btn_name: "Subscribed",
  },
  {
    id: 2,
    btn_name: "Notsubscribed",
  },
  {
    id: 3,
    btn_name: "AllUsers",
  },
];

export default usersBtnData;
