import React, { useEffect, useState } from "react";
import { getPaymentsHistory } from "../../api";
import { Heading, Button } from "../../components";
import paymentsBtnData from "./paymentsBtnData";
import { DataTable } from "../../components/table/table.component";
import { columns } from "./tableColumns";
const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [active, setActive] = useState("Success");
  const handleGetPayments = async (propertyType: string) => {
    const res = await getPaymentsHistory(propertyType);
    console.log(res);
    setPayments(res);
  };
  useEffect(() => {
    handleGetPayments("Success");
  }, []);
  return (
    <div>
      <Heading>Payments</Heading>
      <div className="flex justify-end">
        {paymentsBtnData?.map((data) => (
          <Button
            propsClass={`font-sm rounded-xl px-2 py-1 m-3 ${
              active === data?.btn_name ? "bg-black text-white" : ""
            }`}
            onClick={() => {
              handleGetPayments(data?.btn_name);
              setActive(data?.btn_name);
            }}
          >
            {data?.btn_name}
          </Button>
        ))}
      </div>
      <div className="mt-4">
        <DataTable columns={columns} data={payments} />
      </div>
    </div>
  );
};

export default Payments;
