import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type AgentProps = {
  id: string;
  amount: number;
  status: "pending" | "processing" | "success" | "failed";
  email: string;
};

export const columns: ColumnDef<AgentProps>[] = [
  {
    accessorKey: "order_id",
    header: "Order Id",
  },
  {
    accessorKey: "billing_name",
    header: "Billing Name",
  },
  {
    accessorKey: "billing_tel",
    header: "Billing Number",
  },
  {
    accessorKey: "order_status",
    header: "Order Status",
  },
  {
    accessorKey: "payment_mode",
    header: "Payment Mode",
  },
  {
    accessorKey: "price",
    header: "Price",
  },
  {
    accessorKey: "tracking_id",
    header: "Tracking Id",
  },
  {
    accessorKey: "status_message",
    header: "Status Message",
  },
  {
    accessorKey: "trans_date",
    header: "Transaction Date",
  },
  {
    accessorKey: "status_code",
    header: "Status Code",
  },
  {
    accessorKey: "billing_email",
    header: "Billing Email",
  },
];
