import React, { useRef } from "react";
import ls from "./loginStyle.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks";
import { Button } from "../../../components";
const Login = () => {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const onSubmit = async (e: any) => {
    console.log(usernameRef?.current?.value, passwordRef?.current?.value);
    e.preventDefault();
    auth
      ?.signIn(usernameRef?.current?.value, passwordRef?.current?.value)
      .then((res: any) => {
        console.log(res);
        if (res.statusCode === 400) {
          console.log(res?.message?.info || "Something Went wring");
        } else if (res?.redirectTo) {
          navigate(res?.redirectTo);
        }
      });
  };
  return (
    <div className={ls.main_con}>
      <div className={ls.login_form_con}>
        <div className={ls.heading_con}>
          <h2>Login</h2>
        </div>
        <form onSubmit={onSubmit}>
          <div className={ls.form_input_con}>
            <label htmlFor="email-login">Email</label>
            <input
              type="email"
              name="email"
              id="email-login"
              ref={usernameRef}
            />
          </div>
          <div className={ls.form_input_con}>
            <label htmlFor="password-login">Password</label>
            <input
              type="password"
              name="password"
              id="password-login"
              ref={passwordRef}
            />
          </div>
          <Button propsClass="text-black hover:bg-black hover:text-white">
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
