import React, { useState } from "react";
import { Sidebar } from "../../components";
import { Outlet } from "react-router-dom";
import als from "./appLayoutStyle.module.css";
import { Login } from "../../pages";
import { useAuthContext } from "../../hooks";
const AppLayout = () => {
  const auth = useAuthContext();
  const [isExtended, setIsExtended] = useState(true);
  const toggleExtended = () => {
    setIsExtended((prevState) => !prevState);
  };
  return auth?.isAuthenticated ? (
    <div className={als.main_con}>
      <Sidebar isExtended={isExtended} toggleExtended={toggleExtended} />
      <div
        className={`${als.outlet_con} ${!isExtended && als.extended_outlet}`}
      >
        <Outlet />
      </div>
    </div>
  ) : (
    <Login />
  );
};

export default AppLayout;
