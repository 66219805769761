import React from "react";
import ss from "./sidebarStyle.module.css";
import { Link, useLocation } from "react-router-dom";
import Button from "../button/button";
import { useAuthContext } from "../../hooks";
import {
  dashboardIcon,
  agentsIcon,
  propertiesIcon,
  paymentIcon,
  apiLimitIcon,
  leftArrowIcon,
  rightArrowIcon,
  logo,
  usersIcon,
} from "../../assets";
interface IsExtendedProps {
  isExtended: boolean;
  toggleExtended: () => void;
}
const Sidebar = ({ isExtended, toggleExtended }: IsExtendedProps) => {
  const location = useLocation();
  const auth = useAuthContext();

  const username = auth.user.attributes.email.split('@')[0];
  return (
    <div className={`${ss.main_con} ${!isExtended && ss.main_con_shrunk}`}>
      <div>
        <div className="flex">
          <img src={logo} alt="" />
        </div>
        <div className={ss.links_con}>
          <Link
            className={`${ss.link_con} ${
              location.pathname === "/" && ss.link_selected
            }`}
            to="/"
          >
            <img src={dashboardIcon} className={ss.icon} alt="Dashboard Icon" />
            <div className={`${ss.menu_link} ${ss.opacity_util}`}>
              Dashboard
            </div>
          </Link>
          <Link
            className={`${ss.link_con} ${
              location.pathname === "/agents" && ss.link_selected
            }`}
            to="/agents"
          >
            <img src={agentsIcon} className={ss.icon} alt="Agents Icon" />
            <div className={`${ss.menu_link} ${ss.opacity_util}`}>Agents</div>
          </Link>
          <Link
            className={`${ss.link_con} ${
              location.pathname === "/properties" && ss.link_selected
            }`}
            to="/properties"
          >
            <img
              src={propertiesIcon}
              className={ss.icon}
              alt="Properties Icon"
            />
            <div className={`${ss.menu_link} ${ss.opacity_util} `}>
              Properties
            </div>
          </Link>
          <Link
            className={`${ss.link_con} ${
              location.pathname === "/payments" && ss.link_selected
            }`}
            to="/payments"
          >
            <img src={paymentIcon} className={ss.icon} alt="Payments Icon" />

            <div className={`${ss.menu_link} ${ss.opacity_util}`}>Payments</div>
          </Link>
          <Link
            className={`${ss.link_con} ${
              location.pathname === "/users" && ss.link_selected
            }`}
            to="/users"
          >
            <img src={usersIcon} className={ss.icon} alt="API limit Icon" />
            {<div className={`${ss.menu_link} ${ss.opacity_util}`}>Users</div>}
          </Link>
        </div>
      </div>

      <footer>
        <div className={`${isExtended ? ss.footer_ext : ss.footer_shrunk}`}>
          <p>{username}</p>
          <img
            src={`${isExtended ? leftArrowIcon : rightArrowIcon}`}
            alt="Left Arrow Button"
            className={`${ss.icon} ${ss.left_arrow}`}
            onClick={() => toggleExtended()}
          />
        </div>
        <div className={`${ss.btn_con} ${ss.opacity_util}`}>
          <Button
            onClick={() => auth?.signOut()}
            className="text-white bg-black hover:bg-white hover:text-black px-5 py-2 rounded-xl"
          >
            Sign Out
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default Sidebar;
