import React, { ReactNode, HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
  propsClass?: string;
  otherProps?: any;
}

const Heading = ({ children, propsClass, ...otherProps }: HeadingProps) => {
  const defaultClass = "text-3xl font-bold mb-4";
  return (
    <h1 className={twMerge(defaultClass, propsClass)} {...otherProps}>
      {children}
    </h1>
  );
};

export default Heading;
