import { GET } from "../../helper/restFunctions";
const getUsers = async (userType?: string) => {
  const path = `/admin/users?${userType}`;
  return await GET(path)
    .then((result: any) => {
      return result[1];
    })
    .catch((err: any) => {
      console.log("error on fetching users >", err);
      return err;
    });
};
export default getUsers;
