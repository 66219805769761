import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks";

const Index = () => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const auth = useAuthContext();
  const navigate = useNavigate();
  const onSubmit = async (e: any) => {
    e.preventDefault();
    auth
      ?.newPasswordChallenge(passwordRef?.current?.value || "")
      .then((e: any) => {
        if (e?.statusCode === 200) {
          navigate("/");
        }
      });
  };
  useEffect(() => {
    if (!auth.user) {
      navigate("/");
    }
  }, [auth?.user]);
  return (
    <div className="container p-3 d-flex flex-column min-vh-100 min-vw-100">
      <div className=" d-flex flex-grow-1 justify-content-center align-items-center ">
        <div className="auth-container container d-flex flex-column  p-4 justify-content-center align-items-center">
          <form onSubmit={onSubmit}>
            <div className="form-group my-2">
              <label htmlFor="password">New Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                ref={passwordRef}
              />
            </div>

            <button onClick={onSubmit} className="btn btn-primary my-2">
              Log In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Index;
