import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type AgentProps = {
  id: string;
  amount: number;
  status: "pending" | "processing" | "success" | "failed";
  email: string;
};

export const columns: ColumnDef<AgentProps>[] = [
  {
    accessorKey: "property_key",
    header: "Property Key",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "property_type",
    header: "Property Type",
  },
  {
    accessorKey: "price",
    header: "Price",
  },
  {
    accessorKey: "available_from",
    header: "Available From",
  },
  {
    accessorKey: "address",
    header: "Address",
  },
];
