import { GET } from "../../helper/restFunctions";
const getAgentsInfo = async () => {
  const path = `/admin/agents`;
  return await GET(path)
    .then((result: any) => {
      return result[1];
    })
    .catch((err: any) => {
      console.log("error on fetching agents >", err);
      return err;
    });
};
export default getAgentsInfo;
