import React, { useEffect, useState } from "react";
import { getProperties } from "../../api";
import { Heading, Button } from "../../components";
import propertyBtnData from "./propertyBtnData";
import { DataTable } from "../../components/table/table.component";
import { columns } from "./tableColumns";
const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [active, setActive] = useState("subscribed");
  const handleGetProperties = async (propertyType: string) => {
    const res = await getProperties(propertyType);
    console.log(res);
    setProperties(res);
  };
  useEffect(() => {
    handleGetProperties("subscribed");
  }, []);
  return (
    <div>
      <Heading>Properties</Heading>
      <div className="flex justify-end">
        {propertyBtnData?.map((data) => (
          <Button
            propsClass={`font-sm rounded-xl px-2 py-1 m-3 ${
              active === (data?.btn_name).toLocaleLowerCase()
                ? "bg-black text-white"
                : ""
            }`}
            onClick={() => {
              handleGetProperties((data?.btn_name).toLocaleLowerCase());
              setActive((data?.btn_name).
              toLocaleLowerCase());
            }}
          >
            {data?.btn_name}
          </Button>
        ))}
      </div>
      <div className="mt-4">
        <DataTable columns={columns} data={properties} />
      </div>
    </div>
  );
};

export default Properties;
