import React from "react";

const paymentsBtnData = [
  {
    id: 1,
    btn_name: "Success",
  },
  {
    id: 2,
    btn_name: "Aborted",
  },
  {
    id: 3,
    btn_name: "Invalid",
  },
  {
    id: 4,
    btn_name: "Failure",
  },
  {
    id: 5,
    btn_name: "All_payments",
  },
];

export default paymentsBtnData;
