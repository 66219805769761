import { GET } from "../../helper/restFunctions";
const getPaymentsHistory = async (paymentType?: string) => {
  const path = `/admin/payments?${paymentType}`;
  return await GET(path)
    .then((result: any) => {
      return result[1];
    })
    .catch((err: any) => {
      console.log("error on fetching properties >", err);
      return err;
    });
};
export default getPaymentsHistory;
