import { API_URL } from "../config";
// // ------------------------------POST

export const POST = async (path?: string, data?: any, token?: string) => {
  const res = await fetch(`${API_URL}${path}`, {
    method: "POST",
    mode: "no-cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = await res.json();
      console.log(json);
      return [res?.status, json];
    })
    .then((res) => {
      console.log("s", res);
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${path} >`, error);
      return [500, error];
    });
  return res;
};
// // ------------------------------PATCH

interface patchProps {
  apiUri: string;
  data: Object;
  token?: string;
}
export const PATCH = async ({ apiUri, data, token }: patchProps) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in patch ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};
// // ------------------------------DELETE

export const DELETE = async (apiUri?: string, token?: string) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "DELETE",
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in delete ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

// // ------------------------------GET

export const GET = async (
  apiUri?: string,
  signal?: AbortSignal,
  token?: string
) => {
  const endpoint = `${API_URL}${apiUri}`;
  // console.log("endpoint > ", endpoint);
  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors",
    signal: signal,
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in api ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};
