import React, { ReactNode, ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  propsClass?: string;

}

const Button = ({ children, propsClass, ...otherProps }: ButtonProps) => {
  const defaultClass =
    "hover:bg-black hover:text-white py-3 px-7 text-black bg-white";
  const classNames = twMerge(defaultClass, propsClass);

  return (
    <button className={classNames} {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
