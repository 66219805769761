import React from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "../layout/appLayout/appLayout";
import {
  Dashboard,
  CreateNewPassword,
  Agents,
  Properties,
  Payments,
  Users,
} from "../pages";
const Router = () => {
  return (
    <Routes>
      <Route path="/createNewPassword" element={<CreateNewPassword />} />
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="agents" element={<Agents />} />
        <Route path="properties" element={<Properties />} />
        <Route path="payments" element={<Payments />} />
        <Route path="users" element={<Users />} />
      </Route>
    </Routes>
  );
};

export default Router;
