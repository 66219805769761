import React, { useEffect, useState } from "react";
import { getUsers } from "../../api";
import { Heading, Button } from "../../components";
import { DataTable } from "../../components/table/table.component";
import { columns } from "./tableColumns";
import usersBtnData from "./usersBtnData";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState("Subscribed");
  const handleGetUsers = async (userType: string) => {
    const res = await getUsers(userType);
    console.log(res);
    setUsers(res);
  };
  useEffect(() => {
    handleGetUsers("Subscribed");
  }, []);
  return (
    <div>
      <Heading>Users</Heading>
      <div className="flex justify-end">
        {usersBtnData?.map((data) => (
          <Button
            propsClass={`font-sm rounded-xl px-2 py-1 m-3 ${active === (data?.btn_name)
              ? "bg-black text-white"
              : ""
              }`}
            onClick={() => {
              handleGetUsers((data?.btn_name));
              setActive((data?.btn_name));
            }}
          >
            {data?.btn_name}
          </Button>
        ))}
      </div>
      <div className="mt-4">
        <DataTable columns={columns} data={users} />
      </div>
    </div>
  );
};

export default Users;
