import React, { useEffect, useState } from "react";
import { getAgentsInfo } from "../../api";
import { DataTable } from "../../components/table/table.component";
import { columns } from "./tableColumns";
import { Heading } from "../../components";
const Agents = () => {
  const [agentsInfo, setAgentsInfo] = useState([]);
  const handleGetAgentsInfo = async () => {
    const res = await getAgentsInfo();
    setAgentsInfo(res);
  };
  useEffect(() => {
    handleGetAgentsInfo();
  }, []);
  return (
    <div className="flex-column ">
      <Heading>Agents </Heading>
      {agentsInfo && <DataTable columns={columns} data={agentsInfo} />}
    </div>
  );
};

export default Agents;
