import React from "react";

const propertyBtnData = [
  {
    id: 1,
    btn_name: "Subscribed",
  },
  {
    id: 2,
    btn_name: "Unsubscribed",
  },
  {
    id: 3,
    btn_name: "Active",
  },
  {
    id: 4,
    btn_name: "Inactive",
  },
  {
    id: 5,
    btn_name: "All Properties",
  },
];

export default propertyBtnData;
