import React from "react";
import cs from "./cardStyle.module.css";

interface CardProps {
  title: string;
  description: string;
  count: number;
  img?: string;
}

const Card = ({ title, description, count, img }: CardProps) => {
  return (
    <div className={cs.main_con}>
      <span className={cs.span}></span>
      <div className={cs.sub_con}>
        <div className={cs.hero_con}>
          <h3 className="text-lg font-bold">{title}</h3>
          <p>
            {description} : <span className={cs.count_span}>{count}</span>
          </p>
        </div>
        <div className={cs.img_con}>
          <img className={cs.icon} src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Card;
