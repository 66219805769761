import { GET } from "../../helper/restFunctions";
const getProperties = async (propertyType?: string) => {
  const path = `/admin/properties?${propertyType}`;
  return await GET(path)
    .then((result: any) => {
      return result[1];
    })
    .catch((err: any) => {
      console.log("error on fetching properties >", err);
      return err;
    });
};
export default getProperties;
