import { ColumnDef } from "@tanstack/react-table";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type AgentProps = {
  id: string;
  amount: number;
  status: "pending" | "processing" | "success" | "failed";
  email: string;
};

export const columns: ColumnDef<AgentProps>[] = [
  {
    accessorKey: "user_key",
    header: "User Key",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "aadhar",
    header: "Aadhar Verified",
  },
  
  {
    accessorKey: "email",
    header: "Email",
  },
  
 
  {
    accessorKey: "phone_number",
    header: "Phone Number",
  },
 
];
