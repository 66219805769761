import React, { useEffect, useState } from "react";
import ds from "./dashboardStyle.module.css";
import { Card } from "../../components";
import { getFeaturesCount } from "../../api";
const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<any>(null);

  const handleDashboardData = async () => {
    const res = await getFeaturesCount();
    // console.log(res);
    setDashboardData(res);
  };
  useEffect(() => {
    handleDashboardData();
  }, []);
  return (
    <div className={ds.main_con}>
      <div className={ds.card_con}>
        {dashboardData &&
          dashboardData?.map((data: any) => {
            return (
              <Card
                key={data?.id}
                title={data?.title}
                description={data?.description}
                count={data?.count}
                img={data?.img}
                
              />
              
            );
           
          })}
      </div>
    </div>
  );
};

export default Dashboard;
